import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../common.css';
import {Modal} from "react-bootstrap";
import { withRouter  } from "react-router-dom";
import axios from "axios";

class DialogDeleteProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            projectTitle: props.projectTitle,
            show: true,
            buttonActive: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleInputTitleChange = this.handleInputTitleChange.bind(this);
        this.handleDeleteProject = this.handleDeleteProject.bind(this);
    }

    handleClose() {
        this.props.onClose();
    }

    handleInputTitleChange(event) {
        this.setState({
            buttonActive: event.target.value === this.state.projectTitle
        });
    }

    handleDeleteProject() {
        const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.delete(url, config)
            .then(response => {
                console.log(response);

                this.props.history.replace('/');
            })
            .catch(error => {
                console.log(error.message);
            })
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Delete project</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input type='text' className='form-control' placeholder='Type project title to delete it'
                           onChange={this.handleInputTitleChange}/>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-outline-secondary' onClick={this.handleClose}>Cancel</button>
                    <button className='btn btn-danger' onClick={this.handleDeleteProject}
                            disabled={!this.state.buttonActive}>Delete
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default withRouter(DialogDeleteProject)