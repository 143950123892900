import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Main from './main/main';
import Project from './project/project';
import Login from "./login/login";
import Register from "./register/register";
import {Redirect} from "react-router";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: localStorage.getItem('fromDate') ? new Date(parseInt(localStorage.getItem('fromDate'))) : new Date(),
            toDate: localStorage.getItem('toDate') ? new Date(parseInt(localStorage.getItem('toDate'))) : new Date(),
            token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTokenUpdate = this.handleTokenUpdate.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleDateChange(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        }, () => {
            localStorage.setItem('fromDate', fromDate.getTime());
            localStorage.setItem('toDate', toDate.getTime());
        })
    }

    handleTokenUpdate(token) {
        this.setState({
            token: token
        }, () => {
            localStorage.setItem('token', token);
        })
    }

    handleLogout() {
        this.setState({
            token: ''
        }, () => {
            localStorage.setItem('token', '');
        })
    }

    render() {
        return (
            <div>
                <ReactNotification />
                <Router>
                    <div className='content-area'>
                        <Switch>

                            <Route exact path='/'>
                                {this.state.token !== '' ?
                                    <Main
                                        token={this.state.token}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        onDateChange={this.handleDateChange}
                                        onLogout={this.handleLogout}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/projects/:id/'>
                                {this.state.token !== '' ?
                                    <Project
                                        token={this.state.token}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        onDateChange={this.handleDateChange}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/login/'>
                                <Login onTokenUpdate={this.handleTokenUpdate}/>
                            </Route>

                            <Route path='/register/'>
                                <Register/>
                            </Route>

                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);