import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './countries-table.css';
import './../../common.css';

const ROI_HIGH = 10;
const ROI_LOW = -20;

const ruble = ' ₽';
const dollar = ' $';
const percent = ' %';

function CountryRow(props) {
    let className;
    if (props.country.roi >= ROI_HIGH) {
        className = 'country-row-green';
    } else if (props.country.roi > ROI_LOW) {
        className = 'country-row-yellow';
    } else {
        className = 'country-row-red';
    }

    let selectedClass = 'icon-view';
    if (props.selected) {
        selectedClass = 'icon-view-selected';
    }

    return (
        <tr className={className}>
            <td>{props.country.country}</td>
            <td>{props.country.proceeds + ruble}</td>
            <td>{props.country.income + ruble}</td>
            <td>{props.country.expenses + ruble}</td>
            <td>{props.country.convertion}</td>
            <td>{props.country.convertionPrice + ruble}</td>
            <td>{props.country.cpm + dollar}</td>
            <td>{props.country.ctr + percent}</td>
            <td>{props.country.roi === '-' ? props.country.roi : props.country.roi + percent}</td>
            <td><i className={"fas fa-eye " + selectedClass} onClick={props.onSelect}/></td>
        </tr>
    );
}

// noinspection DuplicatedCode
class CountriesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: props.countries,
            sorting: {
                field: '',
                asc: true
            },
            filterTitle: ''
        }

        this.sortByTitle = this.sortByTitle.bind(this);
        this.sortByProceeds = this.sortByProceeds.bind(this);
        this.sortByIncome = this.sortByIncome.bind(this);
        this.sortByExpenses = this.sortByExpenses.bind(this);
        this.sortByConvertion = this.sortByConvertion.bind(this);
        this.sortByConvertionPrice = this.sortByConvertionPrice.bind(this);
        this.sortByCPM = this.sortByCPM.bind(this);
        this.sortByCTR = this.sortByCTR.bind(this);
        this.sortByROI = this.sortByROI.bind(this);
        this.sortCountries = this.sortCountries.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    sortByTitle() {
        this.sortCountries('country');
    }

    sortByProceeds() {
        this.sortCountries('proceeds');
    }

    sortByIncome() {
        this.sortCountries('income');
    }

    sortByExpenses() {
        this.sortCountries('expenses');
    }

    sortByConvertion() {
        this.sortCountries('convertion');
    }

    sortByConvertionPrice() {
        this.sortCountries('convertionPrice');
    }

    sortByCPM() {
        this.sortCountries('cpm');
    }

    sortByCTR() {
        this.sortCountries('ctr');
    }

    sortByROI() {
        this.sortCountries('roi');
    }

    sortCountries(field) {
        let newAsc = true;
        if (this.state.sorting.field === field) {
            newAsc = !this.state.sorting.asc;
        }

        const countries = this.state.countries;

        for (let i = 0; i < countries.length; i++) {
            if (countries[i][field] === '-') {
                if (newAsc) {
                    countries[i][field] = Number.MAX_VALUE;
                } else {
                    countries[i][field] = -Number.MAX_VALUE;
                }
            }
        }

        for (let i = 0; i < countries.length - 1; i++) {
            for (let j = i + 1; j < countries.length; j++) {
                if ((newAsc && countries[i][field] > countries[j][field]) ||
                    (!newAsc && countries[i][field] < countries[j][field])) {
                    const temp = countries[i];
                    countries[i] = countries[j];
                    countries[j] = temp;
                }
            }
        }

        for (let i = 0; i < countries.length; i++) {
            if (countries[i][field] === Number.MAX_VALUE || countries[i][field] === -Number.MAX_VALUE) {
                countries[i][field] = '-';
            }
        }

        this.setState({
            countries: countries,
            sorting: {
                field: field,
                asc: newAsc
            }
        })
    }

    getSortingSign(field) {
        return this.state.sorting.field === field ? this.state.sorting.asc ? ' ↓' : ' ↑' : '';
    }

    handleSelect(title) {
        let newTitle = title;
        if (newTitle === this.state.filterTitle) {
            newTitle = '';
        }
        this.setState({
            filterTitle: newTitle
        });
        this.props.onSelect(newTitle)
    }

    render() {
        return (
            <table className='table table-hover table-countries'>
                <thead>
                <tr className='countries-table-header'>
                    <th onClick={this.sortByTitle}>{'Страна' + this.getSortingSign('country')}</th>
                    <th onClick={this.sortByProceeds}>{'Чистыми' + this.getSortingSign('proceeds')}</th>
                    <th onClick={this.sortByIncome}>{'Доходы с рекламы' + this.getSortingSign('income')}</th>
                    <th onClick={this.sortByExpenses}>{'Расходы' + this.getSortingSign('expenses')}</th>
                    <th onClick={this.sortByConvertion}>{'Конверсия' + this.getSortingSign('convertion')}</th>
                    <th onClick={this.sortByConvertionPrice}>{'Цена конверсии' + this.getSortingSign('convertionPrice')}</th>
                    <th onClick={this.sortByCPM}>{'CPM' + this.getSortingSign('cpm')}</th>
                    <th onClick={this.sortByCTR}>{'CTR' + this.getSortingSign('ctr')}</th>
                    <th onClick={this.sortByROI}>{'ROI' + this.getSortingSign('roi')}</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.countries.map((country) => {
                    const selected = this.state.filterTitle === country.country;
                    return (
                        <CountryRow country={country} key={country.country} selected={selected}
                                    onSelect={() => this.handleSelect(country.country)}/>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

export default CountriesTable