import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import './../common.css';
import ProjectsTable from './projects-table/projects-table.js';
import Header from './header/header.js';
import Card from '../common-components/data-card/data-card';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from 'axios';

const ruble = ' ₽';
const dollar = ' $';
const percent = ' %';

function CardsPanel(props) {
    return (
        <div className='container cards-panel'>
            <div className='row'>
                <Card description='Чистыми' value={props.data.profit + ruble} colorstyle='green'/>
                <Card description='Доходы с рекламы' value={props.data.income + ruble} colorstyle='green-light'/>
                <Card description='Расходы' value={props.data.expenses + ruble} colorstyle='red'/>
                <Card description='Цена конверсии' value={props.data.convertionPrice + ruble} colorstyle='yellow'/>
                <Card description='CPM' value={props.data.cpm + dollar} colorstyle='blue'/>
                <Card description='Подписки' value={props.data.subscriptions + ruble} colorstyle='green-light'/>
            </div>
        </div>
    );
}

function Chart(props) {
    let incomes = [];
    let subsIncomes = [];
    let convertionPrices = [];
    let cpms = [];
    let profits = [];
    let expenses = []
    let dates = [];

    for (let i = 0; i < props.data.length; i++) {
        incomes.push(props.data[i].income);
        subsIncomes.push(props.data[i].subs);
        convertionPrices.push(props.data[i].convertionPrice);
        cpms.push(props.data[i].cpm);
        profits.push(props.data[i].profit);
        expenses.push(props.data[i].expenses);
        dates.push(props.data[i].date);
    }
    // noinspection JSUnusedGlobalSymbols
    const options = {
        title: {
            text: 'Statistics'
        },
        xAxis: {
            tickInterval: 1,
            labels: {
                enabled: true,
                formatter: function () {
                    return dates[this.value];
                }
            }
        },
        yAxis: [{title: ''}, {title: ''}],
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Доходы с рекламы',
                data: incomes,
                yAxis: 0
            },
            {
                type: 'column',
                name: 'Подписки',
                data: subsIncomes,
                yAxis: 0
            },
            {
                name: 'Чистыми',
                data: profits,
                stacking: null,
                yAxis: 0
            },
            {
                name: 'Цена конверсии',
                data: convertionPrices,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'CPM',
                data: cpms,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'Расходы',
                data: expenses,
                stacking: null,
                yAxis: 0
            }
        ]
    }
    return (
        <div className='chart'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}

class FilterableChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            fromDate: props.fromDate,
            toDate: props.toDate,
            isLoaded: false,
            error: null,
            data: {},
            projects: [],
            chart_data: [],
            filterId: -1
        }

        this.handleSelectProject = this.handleSelectProject.bind(this);
    }

    loadProjects() {
        const url = 'https://test.artmik.ru/api/projects?from_date=' + this.state.fromDate + '&to_date=' + this.state.toDate;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(result => {
                console.log(result)
                const chart_data = [];
                result.data.chart_data.forEach(item => {
                    chart_data.push({
                        date: item.date,
                        income: item.income,
                        convertionPrice: item.convertionPrice,
                        cpm: item.cpm,
                        profit: item.profit,
                        expenses: item.expenses,
                        subs: 0
                    })
                });
                const subsKeys = Object.keys(result.data.subs_data);
                subsKeys.forEach(key => {
                    let exists = false;
                    chart_data.forEach(item => {
                        if (result.data.subs_data[key].report_date === item.date) {
                            exists = true;
                            item.subs += result.data.subs_data[key].income_calculated;
                        }
                    })
                    if (!exists) {
                        if (result.data.subs_data[key].income_calculated > 0) {
                            chart_data.push({
                                date: result.data.subs_data[key].report_date,
                                income: 0,
                                convertionPrice: 0,
                                cpm: 0,
                                profit: 0,
                                expenses: 0,
                                subs: result.data.subs_data[key].income_calculated
                            })
                        }
                    }
                })
                chart_data.sort((d1, d2) => {
                    return d1.date > d2.date ? 1 : -1;
                })
                this.setState({
                    isLoaded: true,
                    projects: result.data.table_data,
                    data: {
                        profit: result.data.total_profit,
                        income: result.data.total_income,
                        proceeds: result.data.total_proceeds,
                        expenses: result.data.total_expenses,
                        convertionPrice: result.data.total_convertion_price,
                        cpm: result.data.total_cpm,
                        subscriptions: result.data.total_subs
                    },
                    chart_data: chart_data
                });
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadProjects();
    }

    handleSelectProject(id) {
        this.setState({
            filterId: id
        });
        if (id > -1) {
            this.loadProjectHistoryById(id)
        } else {
            this.loadProjects()
        }
    }

    loadProjectHistoryById(id) {
        const url = 'https://test.artmik.ru/api/projects/' + id + '?from_date=' + this.state.fromDate + '&to_date=' + this.state.toDate;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(result => {
                const chart_data = [];
                result.data.chart_data.forEach(item => {
                    chart_data.push({
                        date: item.date,
                        income: item.income,
                        convertionPrice: item.convertionPrice,
                        cpm: item.cpm,
                        profit: item.profit,
                        expenses: item.expenses,
                        subs: 0
                    })
                });
                const subsKeys = Object.keys(result.data.subs_data);
                subsKeys.forEach(key => {
                    let exists = false;
                    chart_data.forEach(item => {
                        if (result.data.subs_data[key].report_date === item.date) {
                            exists = true;
                            item.subs = result.data.subs_data[key].income_calculated;
                        }
                    })
                    if (!exists) {
                        if (result.data.subs_data[key].income_calculated > 0) {
                            chart_data.push({
                                date: result.data.subs_data[key].report_date,
                                income: 0,
                                convertionPrice: 0,
                                cpm: 0,
                                profit: 0,
                                expenses: 0,
                                subs: result.data.subs_data[key].income_calculated
                            })
                        }
                    }
                })
                chart_data.sort((d1, d2) => {
                    return d1.date > d2.date ? 1 : -1;
                })
                this.setState({
                    chart_data: chart_data
                })
            })
    }

    render() {
        if (this.state.error) {
            return <div>Error: {this.state.error.message}</div>;
        } else if (!this.state.isLoaded) {
            return <div>Loading...</div>;
        } else
            return (
                <div>
                    <CardsPanel data={this.state.data}/>
                    <Chart data={this.state.chart_data}/>
                    <ProjectsTable projects={this.state.projects} onSelect={this.handleSelectProject}/>
                </div>
            );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            fromDate: props.fromDate,
            toDate: props.toDate,
            updateKey: false
        }

        this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCreateProject = this.handleCreateProject.bind(this);
    }

    handleDateRangeChanged(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        this.props.onDateChange(fromDate, toDate);
    }

    handleLogout() {
        this.props.onLogout();
    }

    handleCreateProject() {
        this.setState(prevState => ({
            updateKey: !prevState.updateKey
        }));
    }

    render() {
        const fromDateFormat = this.state.fromDate.getFullYear() + "-" + ("0" + (this.state.fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.fromDate.getDate()).slice(-2);
        const toDateFormat = this.state.toDate.getFullYear() + "-" + ("0" + (this.state.toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.toDate.getDate()).slice(-2);

        return (
            <div>
                <Header
                    token={this.state.token}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    onDateRangePicked={this.handleDateRangeChanged}
                    onCreateProject={this.handleCreateProject}/>
                <FilterableChart
                    key={fromDateFormat + toDateFormat + this.state.updateKey}
                    token={this.state.token}
                    fromDate={fromDateFormat}
                    toDate={toDateFormat}/>
                <div className='button-logout-holder'>
                    <button className='btn btn-outline-danger' onClick={this.handleLogout}>Log out</button>
                </div>
            </div>
        );
    }
}

export default Main