import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './project.css';
import './../common.css';
import DateRangeSelector from '../common-components/date-range-selector/date-range-selector.js';
import DialogEvents from "./events-dialog/events-dialog.js";
import DialogSubs from './subs-dialog/subs-dialog.js';
import DialogDeleteProject from "./delete-dialog/delete-dialog.js";
import DialogUploadFile from "./upload-dialog/upload-dialog.js";
import Card from '../common-components/data-card/data-card';
import CountriesTable from './countries-table/countries-table.js';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AnnotationsFactory from "highcharts/modules/annotations";
import {withRouter} from "react-router";
import axios from "axios";
// import DialogParams from "./params-dialog/params-dialog";

AnnotationsFactory(Highcharts);

const ruble = ' ₽';
const dollar = ' $';
const percent = ' %';

class ProjectMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            projectTitle: props.projectTitle,
            showingMenu: false,
            showingDeleteProjectDialog: false,
            showingParamsDialog: false
        }

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleParamsClick = this.handleParamsClick.bind(this);
        this.handleHideDialogs = this.handleHideDialogs.bind(this);
    }

    handleMenuClick() {
        this.setState(prevState => ({
            showingMenu: !prevState.showingMenu
        }))
    }

    handleDeleteClick() {
        this.setState({
            showingDeleteProjectDialog: true
        });
    }

    handleParamsClick() {
        this.setState({
            showingParamsDialog: true
        })
    }

    handleHideDialogs() {
        this.setState({
            showingDeleteProjectDialog: false,
            showingParamsDialog: false
        });
    }

    render() {
        return (
            <div className="dropdown">
                <i className="icon-settings fas fa-cog" onClick={this.handleMenuClick}/>
                {this.state.showingMenu ?
                    <div className='menu-holder'>
                        <div className='menu'>
                            <ul>
                                <li className='list-group-item'>
                                    <button className='btn btn-danger' onClick={this.handleDeleteClick}>Delete project</button>
                                    {this.state.showingDeleteProjectDialog ?
                                        <DialogDeleteProject
                                            token={this.state.token}
                                            projectId={this.state.projectId}
                                            projectTitle={this.state.projectTitle}
                                            onClose={this.handleHideDialogs}/>
                                        : null}
                                </li>
                                {/*<li className='list-group-item'>*/}
                                {/*    <button className='btn btn-outline-secondary' onClick={this.handleParamsClick}>Parameters</button>*/}
                                {/*    {this.state.showingParamsDialog ?*/}
                                {/*        <DialogParams*/}
                                {/*            token={this.state.token}*/}
                                {/*            projectId={this.state.projectId}*/}
                                {/*            projectTitle={this.state.projectTitle}*/}
                                {/*            onClose={this.handleHideDialogs}/>*/}
                                {/*        : null}*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}

class ProjectHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            title: props.title,
            fromDate: props.fromDate,
            toDate: props.toDate
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    }

    handleDateRangeChange(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        this.props.onDateRangePicked(fromDate, toDate);
    }

    render() {
        return (
            <div className='flex spaced-between project-header'>
                <div className='flex centered-vertical minified'>
                    <Link to='/'>
                        <i className='arrow-back fas fa-long-arrow-alt-left'/>
                    </Link>
                    &emsp;
                    <h1>{this.state.title}</h1>
                </div>
                <div className='flex minified'>
                    <DateRangeSelector
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        onChange={this.handleDateRangeChange}/>
                    &emsp;
                    <ProjectMenu
                        token={this.state.token}
                        projectId={this.state.projectId}
                        projectTitle={this.state.title}/>
                </div>
            </div>
        );
    }

}

class ButtonsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            subs: props.subs,
            showUploadDialog: false,
            showEventsDialog: false,
            showSubsDialog: false
        }

        this.handleDataClick = this.handleDataClick.bind(this);
        this.handleEventsClick = this.handleEventsClick.bind(this);
        this.handleSubsClick = this.handleSubsClick.bind(this);
        this.handleHideDialogs = this.handleHideDialogs.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleDataClick() {
        this.setState({
            showUploadDialog: true
        });
    }

    handleEventsClick() {
        this.setState({
            showEventsDialog: true
        })
    }

    handleSubsClick() {
        this.setState({
            showSubsDialog: true
        });
    }

    handleHideDialogs() {
        this.setState({
            showUploadDialog: false,
            showEventsDialog: false,
            showSubsDialog: false
        });
    }

    handleUpdate() {
        this.props.onDataUpdated();
    }

    render() {
        return (
            <div className='flex buttons-panel minified'>
                <button className='btn btn-outline-success' onClick={this.handleDataClick}>Data</button>
                <button className='btn btn-outline-warning button-project'
                        onClick={this.handleSubsClick}>Subscriptions
                </button>
                <button className='btn btn-outline-primary button-project' onClick={this.handleEventsClick}>Events
                </button>

                {this.state.showUploadDialog ?
                    <DialogUploadFile
                        token={this.state.token}
                        projectId={this.state.projectId}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={this.handleUpdate}/>
                    : null
                }
                {this.state.showEventsDialog ?
                    <DialogEvents
                        token={this.state.token}
                        projectId={this.state.projectId}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={this.handleUpdate}/>
                    : null
                }
                {this.state.showSubsDialog ?
                    <DialogSubs
                        token={this.state.token}
                        projectId={this.state.projectId}
                        subs={this.state.subs}
                        onClose={this.handleHideDialogs}
                        onDataUpdated={this.handleUpdate}/>
                    : null
                }
            </div>
        );
    }

}

function CardsPanel(props) {
    return (
        <div className='flex cards-panel'>
            <Card description='Чистыми' value={props.data.profit + ruble} colorstyle='green'/>
            <Card description='Доходы с рекламы' value={props.data.income + ruble} colorstyle='green-light'/>
            <Card description='Расходы' value={props.data.expenses + ruble} colorstyle='red'/>
            <Card description='Цена конверсии' value={props.data.convertionPrice + ruble} colorstyle='yellow'/>
            <Card description='CPM' value={props.data.cpm + dollar} colorstyle='blue'/>
            <Card description='Подписки' value={props.data.subscriptions + ruble} colorstyle='green-light'/>
        </div>
    );
}

function Chart(props) {
    let incomes = [];
    let subsIncomes = [];
    let convertionPrices = [];
    let cpms = [];
    let profits = [];
    let expenses = []
    let dates = [];

    for (let i = 0; i < props.data.length; i++) {
        incomes.push(props.data[i].income);
        subsIncomes.push(props.data[i].subs);
        convertionPrices.push(props.data[i].convertionPrice);
        cpms.push(props.data[i].cpm);
        profits.push(props.data[i].profit);
        expenses.push(props.data[i].expenses);
        dates.push(props.data[i].date);
    }

    let labels = [];

    for (let i = 0; i < props.events.length; i++) {
        const date = props.events[i].date
        const label = {
            text: props.events[i].text,
            padding: 5,
            point: {
                x: dates.indexOf(date),
                y: 0,
                xAxis: 0,
                yAxis: 0
            }
        }
        labels.push(label);
    }

    const options = {
        title: {
            text: 'Statistics'
        },
        xAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                enabled: true,
                formatter: function () {
                    return dates[this.value];
                }
            }
        },
        yAxis: [{title: ''}, {title: ''}],
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Доходы с рекламы',
                data: incomes,
                yAxis: 0
            },
            {
                type: 'column',
                name: 'Подписки',
                data: subsIncomes,
                yAxis: 0
            },
            {
                name: 'Чистыми',
                data: profits,
                yAxis: 0,
                stacking: null,
            },
            {
                name: 'Цена конверсии',
                data: convertionPrices,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'CPM',
                data: cpms,
                stacking: null,
                visible: false,
                yAxis: 1
            },
            {
                name: 'Расходы',
                data: expenses,
                stacking: null,
                yAxis: 0
            }
        ],

        annotations: labels.map(label => ({
                labelOptions: {
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    verticalAlign: 'bottom',
                    allowOverlap: true,
                    overflow: 'justify'
                },
                labels: label
        }))
    }
    return (
        <div className='chart'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}

class FilterableChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            projectId: props.projectId,
            fromDate: props.fromDate,
            toDate: props.toDate,
            data: props.data,
            projectData: {},
            isLoaded: false,
            error: null,
            events: props.events,
            countries: props.countries,
            filterTitle: ''
        }

        this.handleSelectCountry = this.handleSelectCountry.bind(this);
    }

    loadProjectData() {
        const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId + '?from_date=' + this.state.fromDate + '&to_date=' + this.state.toDate;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response);
                const data = [];
                response.data.chart_data.forEach(item => {
                    data.push({
                        date: item.date,
                        income: item.income,
                        convertionPrice: item.convertionPrice,
                        cpm: item.cpm,
                        profit: item.profit,
                        expenses: item.expenses,
                        subs: 0
                    })
                });
                const subsKeys = Object.keys(response.data.subs_data);
                subsKeys.forEach(key => {
                    let exists = false;
                    data.forEach(item => {
                        if (response.data.subs_data[key].report_date === item.date) {
                            exists = true;
                            item.subs = response.data.subs_data[key].income_calculated;
                        }
                    })
                    if (!exists) {
                        if (response.data.subs_data[key].income_calculated > 0) {
                            data.push({
                                date: response.data.subs_data[key].report_date,
                                income: 0,
                                convertionPrice: 0,
                                cpm: 0,
                                profit: 0,
                                expenses: 0,
                                subs: response.data.subs_data[key].income_calculated
                            })
                        }
                    }
                })
                data.sort((d1, d2) => {
                    return d1.date > d2.date ? 1 : -1
                })
                // noinspection JSUnresolvedVariable
                this.setState({
                    isLoaded: true,
                    projectData: {
                        title: response.data.name,
                        profit: response.data.total_profit,
                        income: response.data.total_income,
                        convertionPrice: response.data.total_convertion_price,
                        cpm: response.data.total_cpm,
                        proceeds: response.data.total_proceeds,
                        expenses: response.data.total_expenses,
                        subscriptions: response.data.total_subs
                    },
                    data: data,
                    countries: response.data.table_data,
                    events: response.data.event_data.map(event => {
                        return {
                            id: event.id,
                            text: event.event_text,
                            date: event.event_date
                        }
                    })
                });
                this.props.onUpdate(response.data.name);
            })
            .catch(error => this.setState({
                isLoaded: true,
                error: error
            }));
    }

    loadCountryDataByTitle(title) {
        const data = [];
        const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId + '/country/' + title
            + '?from_date=' + this.state.fromDate + '&to_date=' + this.state.toDate;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response);
                response.data.chart_data.forEach(item => {
                    data.push({
                        date: item.report_date,
                        income: item.income_calculated,
                        convertionPrice: item.conversion_costs,
                        cpm: item.ecpm_calculated,
                        profit: item.proceeds,
                        expenses: item.ad_expenses_calculated,
                        subs: 0
                    })
                })
                this.setState({
                    data: data,
                    isLoaded: true
                })
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadProjectData();
    }

    handleSelectCountry(title) {
        this.setState({
            filterTitle: title
        });
        if (title !== '') {
            this.loadCountryDataByTitle(title);
        } else {
            this.loadProjectData();
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div>{this.state.error.message}</div>
            );
        } else if (!this.state.isLoaded) {
            return (
                <div>Loading...</div>
            );
        } else {
            return (
                <div>
                    <CardsPanel data={this.state.projectData}/>
                    <Chart
                        data={this.state.data}
                        events={this.state.events}/>
                    <CountriesTable countries={this.state.countries} onSelect={this.handleSelectCountry}/>
                </div>
            );
        }
    }
}

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            title: '',
            projectId: this.props.match.params.id,
            fromDate: props.fromDate,
            toDate: props.toDate,
            data: {},
            chart_data: [],
            table_data: [],
            updateData: false
        }

        this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
        this.handleProjectUpdate = this.handleProjectUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleDateRangeChanged(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        this.props.onDateChange(fromDate, toDate);
    }

    handleProjectUpdate(title) {
        this.setState({
            title: title
        });
    }

    handleUpdate() {
        this.setState(prevState => ({
            updateData: !prevState.updateData
        }));
    }

    render() {
        const fromDateFormat = this.state.fromDate.getFullYear() + "-" + ("0" + (this.state.fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.fromDate.getDate()).slice(-2);
        const toDateFormat = this.state.toDate.getFullYear() + "-" + ("0" + (this.state.toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + this.state.toDate.getDate()).slice(-2);

        return (
            <div>
                <ProjectHeader
                    token={this.state.token}
                    projectId={this.state.projectId}
                    key={this.state.title + this.state.updateData}
                    title={this.state.title}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    onDateRangePicked={this.handleDateRangeChanged}/>

                <ButtonsPanel
                    token={this.state.token}
                    projectId={this.state.projectId}
                    subs={this.state.subscriptions}
                    onDataUpdated={this.handleUpdate}/>

                <FilterableChart
                    token={this.state.token}
                    key={fromDateFormat + toDateFormat + this.state.updateData}
                    projectId={this.state.projectId}
                    data={this.state.chart_data}
                    events={[]}
                    countries={this.state.table_data}
                    fromDate={fromDateFormat}
                    toDate={toDateFormat}
                    onUpdate={this.handleProjectUpdate}/>
            </div>
        );
    }
}

export default withRouter(Project)