import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './projects-table.css';
import './../../common.css';

const ROI_HIGH = 10;
const ROI_LOW = -20

const ruble = ' ₽';
const dollar = ' $';
const percent = ' %';

function ProjectRow(props) {
    const path = '/projects/' + props.project.id + '/';
    let className;
    if (props.project.roi >= ROI_HIGH) {
        className = 'project-row-green';
    } else if (props.project.roi > ROI_LOW) {
        className = 'project-row-yellow';
    } else {
        className = 'project-row-red';
    }

    let selectedClass = 'icon-view';
    if (props.selected) {
        selectedClass = 'icon-view-selected';
    }

    return (
        <tr className={className}>
            <td><Link className='project-link' to={path}>{props.project.title}</Link></td>
            <td>{props.project.profit + ruble}</td>
            <td>{props.project.income + ruble}</td>
            <td>{props.project.subs + ruble}</td>
            <td>{props.project.expenses + ruble}</td>
            <td>{props.project.convertion}</td>
            <td>{props.project.convertionPrice + ruble}</td>
            <td>{props.project.cpm + dollar}</td>
            <td>{props.project.ctr + percent}</td>
            <td>{props.project.roi === '-' ? props.project.roi : props.project.roi + percent}</td>
            <td><i className={"fas fa-eye " + selectedClass} onClick={props.onSelect}/></td>
        </tr>
    );
}

// noinspection DuplicatedCode
class ProjectsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: props.projects,
            sorting: {
                field: '',
                asc: true
            },
            filterId: -1
        }

        this.sortProjects = this.sortProjects.bind(this);
        this.sortByTitle = this.sortByTitle.bind(this);
        this.sortByProceeds = this.sortByProceeds.bind(this);
        this.sortByIncome = this.sortByIncome.bind(this);
        this.sortByExpenses = this.sortByExpenses.bind(this);
        this.sortByConvertion = this.sortByConvertion.bind(this);
        this.sortByConvertionPrice = this.sortByConvertionPrice.bind(this);
        this.sortByCPM = this.sortByCPM.bind(this);
        this.sortByCTR = this.sortByCTR.bind(this);
        this.sortByROI = this.sortByROI.bind(this);
        this.sortBySubs = this.sortBySubs.bind(this);
        this.sortByProfit = this.sortByProfit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    sortByTitle() {
        this.sortProjects('title');
    }

    sortByProceeds() {
        this.sortProjects('proceeds');
    }

    sortByIncome() {
        this.sortProjects('income');
    }

    sortByExpenses() {
        this.sortProjects('expenses');
    }

    sortByConvertion() {
        this.sortProjects('convertion');
    }

    sortByConvertionPrice() {
        this.sortProjects('convertionPrice');
    }

    sortByCPM() {
        this.sortProjects('cpm');
    }

    sortByCTR() {
        this.sortProjects('ctr');
    }

    sortByROI() {
        this.sortProjects('roi');
    }

    sortBySubs() {
        this.sortProjects('subs');
    }

    sortByProfit() {
        this.sortProjects('profit');
    }

    sortProjects(field) {
        let newAsc = true;
        if (this.state.sorting.field === field) {
            newAsc = !this.state.sorting.asc;
        }

        const projects = this.state.projects;

        for (let i = 0; i < projects.length; i++) {
            if (projects[i][field] === '-') {
                if (newAsc) {
                    projects[i][field] = Number.MAX_VALUE;
                } else {
                    projects[i][field] = -Number.MAX_VALUE;
                }
            }
        }

        for (let i = 0; i < projects.length - 1; i++) {
            for (let j = i + 1; j < projects.length; j++) {
                if ((newAsc && projects[i][field] > projects[j][field]) ||
                    (!newAsc && projects[i][field] < projects[j][field])) {
                    const temp = projects[i];
                    projects[i] = projects[j];
                    projects[j] = temp;
                }
            }
        }

        for (let i = 0; i < projects.length; i++) {
            if (projects[i][field] === Number.MAX_VALUE || projects[i][field] === -Number.MAX_VALUE) {
                projects[i][field] = '-';
            }
        }

        this.setState({
            projects: projects,
            sorting: {
                field: field,
                asc: newAsc
            }
        })
    }

    getSortingSign(field) {
        return this.state.sorting.field === field ? this.state.sorting.asc ? ' ↓' : ' ↑' : '';
    }

    handleSelect(id) {
        let newId = id;
        if (newId === this.state.filterId) {
            newId = -1;
        }
        this.setState({
            filterId: newId
        });
        this.props.onSelect(newId)
    }

    render() {
        return (
            <table className='table table-hover table-projects'>
                <thead>
                <tr className='projects-table-header'>
                    <th onClick={this.sortByTitle}>{'Проект' + this.getSortingSign('title')}</th>
                    <th onClick={this.sortByProfit}>{'Чистыми' + this.getSortingSign('profit')}</th>
                    <th onClick={this.sortByIncome}>{'Доходы с рекламы' + this.getSortingSign('income')}</th>
                    <th onClick={this.sortBySubs}>{'Подписки' + this.getSortingSign('subs')}</th>
                    <th onClick={this.sortByExpenses}>{'Расходы' + this.getSortingSign('expenses')}</th>
                    <th onClick={this.sortByConvertion}>{'Конверсия' + this.getSortingSign('convertion')}</th>
                    <th onClick={this.sortByConvertionPrice}>{'Цена конверсии' + this.getSortingSign('convertionPrice')}</th>
                    <th onClick={this.sortByCPM}>{'CPM' + this.getSortingSign('cpm')}</th>
                    <th onClick={this.sortByCTR}>{'CTR' + this.getSortingSign('ctr')}</th>
                    <th onClick={this.sortByROI}>{'ROI' + this.getSortingSign('roi')}</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.projects.map((project) => {
                    const selected = this.state.filterId === project.id;
                    return (
                        <ProjectRow project={project} key={project.id} selected={selected}
                                    onSelect={() => this.handleSelect(project.id)}/>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

export default ProjectsTable