import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './data-card.css';
import '../../common.css';

function Card(props) {
    return (
        <div className={'col centered-horizontal data-card data-card-' + props.colorstyle} onClick={props.onClick}>
            <h1 className='data-card-coast'><b>{props.value}</b></h1>
            {props.description}
        </div>
    );
}

export default Card
