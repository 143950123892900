import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './events-dialog.css';
import '../../common.css';
import {Modal} from "react-bootstrap";
import axios from "axios";
import DatePicker, {registerLocale} from "react-datepicker";
import dateFormat, {masks} from "dateformat";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import {store} from "react-notifications-component";

registerLocale('ru', ru)

function convertDateToString(date) {
    return date.getFullYear() + "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("0" + date.getDate()).slice(-2);
}

class EventRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            eventId: props.eventId,
            projectId: props.projectId,
            text: props.text,
            date: props.date,
            isNew: props.isNew,
            newText: props.text,
            newDate: props.date
        }

        this.handleRemove = this.handleRemove.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
    }

    handleRemove() {
        if (this.state.isNew) {
            this.props.onRemove(this.state.eventId);
        } else {
            const notificationSuccess = {
                title: 'Delete event',
                message: 'Success',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            }

            const notificationError = {
                ...notificationSuccess,
                message: 'Error',
                type: 'danger'
            }

            const config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.token
                }
            }
            const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId + '/events?event_date=' + this.state.date;
            axios.delete(url, config)
                .then(result => {
                    if (result.data.status === 'success') {
                        store.addNotification(notificationSuccess)
                        this.props.onRemove(this.state.eventId);
                        this.props.onDataUpdated();
                    } else {
                        store.addNotification(notificationError)
                    }
                })
                .catch(() => {
                    store.addNotification(notificationError)
                })
        }
    }

    handleTextChange(event) {
        this.setState({
            newText: event.target.value
        })
    }

    handleDateChange(date) {
        const dateString = dateFormat(date, 'yyyy-mm-dd');
        this.setState({
            newDate: dateString
        });
    }

    handleConfirmClick() {
        const notificationSuccess = {
            title: 'Create/update event',
            message: 'Success',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const notificationError = {
            ...notificationSuccess,
            message: 'Error',
            type: 'danger'
        }

        const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId + '/events';
        const requestConfig = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        const requestBody = {
            event_text: this.state.newText,
            event_date: this.state.newDate,
            old_event_date: this.state.date
        }
        axios.post(url, requestBody, requestConfig)
            .then(result => {
                if (result.data.status === 'success') {
                    store.addNotification(notificationSuccess)
                    this.props.onUpdate(this.state.eventId, this.state.newText, this.state.newDate);
                    this.props.onDataUpdated();
                    this.setState({
                        isNew: false,
                        text: this.state.newText,
                        date: this.state.newDate
                    })
                } else {
                    store.addNotification(notificationError)
                }
            })
            .catch(() => {
                store.addNotification(notificationError)
            })
    }

    render() {
        return (
            <div className='flex event-row'>
                <DatePicker
                    className='form-control event-form-input'
                    selected={Date.parse(this.state.newDate)}
                    locale='ru'
                    dateFormat="dd.MM.yyyy"
                    onChange={(date) => {
                        this.handleDateChange(date)
                    }}/>
                &emsp;
                <input type='text' defaultValue={this.state.text} className='form-control event-form-input'
                       placeholder='Event text' onChange={this.handleTextChange}/>
                &emsp;
                <button className='btn btn-success' onClick={this.handleConfirmClick}
                        disabled={(this.state.text === this.state.newText && this.state.date === this.state.newDate) || this.state.newText === ''}>
                    <i className="fas fa-check event-form-input"/>
                </button>
                &emsp;
                <button className='btn btn-danger' onClick={this.handleRemove}><i
                    className="fas fa-times event-form-input"/>
                </button>
            </div>
        );
    }
}

class EventsList extends React.Component {
    constructor(props) {
        super(props);

        let maxId = 0;
        if (props.events.length > 0) {
            for (let i = 0; i < props.events.length; i++) {
                if (props.events[i].id > maxId) {
                    maxId = props.events[i].id;
                }
            }
        }

        this.state = {
            token: props.token,
            projectId: props.projectId,
            events: props.events,
            lastId: maxId
        }

        this.handleCreateEventClick = this.handleCreateEventClick.bind(this);
        this.handleRemoveEventClick = this.handleRemoveEventClick.bind(this);
        this.handleUpdateEventClick = this.handleUpdateEventClick.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleCreateEventClick() {
        const event = {
            id: this.state.lastId + 1,
            date: convertDateToString(new Date()),
            text: '',
            isNew: true
        }
        this.props.onAdd(event);
    }

    handleRemoveEventClick(id) {
        this.props.onRemove(id);
    }

    handleUpdateEventClick(id, text, date) {
        this.props.onUpdate(id, text, date)
    }

    handleUpdate() {
        this.props.onDataUpdated();
    }

    render() {
        return (
            <div>
                <div className='centered'>
                    <button className='btn btn-outline-success' onClick={this.handleCreateEventClick}>Create event
                    </button>
                </div>
                {this.state.events.map(event =>
                    <EventRow
                        token={this.state.token}
                        projectId={this.state.projectId}
                        eventId={event.id}
                        key={event.id}
                        date={event.date}
                        text={event.text}
                        isNew={event.isNew}
                        onRemove={this.handleRemoveEventClick}
                        onUpdate={this.handleUpdateEventClick}
                        onDataUpdated={this.handleUpdate}
                    />
                )}
            </div>
        );
    }
}

class DialogEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            events: [],
            isLoaded: false,
            error: null,
            wasUpdated: false
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleEventAdd = this.handleEventAdd.bind(this);
        this.handleEventRemove = this.handleEventRemove.bind(this);
        this.handleEventUpdate = this.handleEventUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    loadEvents() {
        const url = 'https://test.artmik.ru/api/projects/' + this.state.projectId + '/events';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                const events = [];
                response.data.events.forEach(event => {
                    events.push({
                        id: event.id,
                        text: event.event_text,
                        date: event.event_date,
                        isNew: false
                    })
                })
                this.setState({
                    events: events,
                    isLoaded: true
                })
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadEvents();
    }

    handleClose() {
        if (this.state.wasUpdated) {
            this.props.onDataUpdated();
        }
        this.props.onClose();
    }

    handleEventAdd(event) {
        this.setState(prevState => ({
            events: [...prevState.events, event]
        }));
    }

    handleEventRemove(id) {
        this.setState(prevState => ({
            events: prevState.events.filter(event => event.id !== id)
        }));
    }

    handleEventUpdate(id, text, date) {
        const events = this.state.events;
        for (let i = 0; i < events.length; i++) {
            if (events[i].id === id) {
                events[i].text = text;
                events[i].date = date;
                events[i].isNew = false;
            }
        }
        this.setState({
            events: events
        })
    }

    handleUpdate() {
        this.setState({
            wasUpdated: true
        });
    }

    render() {
        return (
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Events management</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <EventsList
                        token={this.state.token}
                        projectId={this.state.projectId}
                        key={this.state.events.length}
                        events={this.state.events}
                        onAdd={this.handleEventAdd}
                        onRemove={this.handleEventRemove}
                        onUpdate={this.handleEventUpdate}
                        onDataUpdated={this.handleUpdate}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={this.handleClose}>Cancel</button>
                    {/*<button className='btn btn-primary' onClick={this.handleSaveChanges}>Save changes</button>*/}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DialogEvents